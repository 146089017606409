export const actionTypes = {
    PAGE_RESET: 'PAGE_RESET',
    PAGE_LIST_FAILURE: 'PAGE_LIST_FAILURE',
    PAGE_LIST_REQUEST: 'PAGE_LIST_REQUEST',
    PAGE_LIST_REQUEST_REQUESTING: 'PAGE_LIST_REQUEST_REQUESTING',
    PAGE_LIST_REQUEST_SUCCESS: 'PAGE_LIST_REQUEST_SUCCESS',
    PAGE_LIST_REQUEST_ERROR: 'PAGE_LIST_REQUEST_ERROR',
    PAGE_CREATE_FAILURE: 'PAGE_CREATE_FAILURE',
    PAGE_CREATE_REQUEST: 'PAGE_CREATE_REQUEST',
    PAGE_CREATE_REQUEST_REQUESTING: 'PAGE_CREATE_REQUEST_REQUESTING',
    PAGE_CREATE_REQUEST_SUCCESS: 'PAGE_CREATE_REQUEST_SUCCESS',
    PAGE_CREATE_REQUEST_ERROR: 'PAGE_CREATE_REQUEST_ERROR',
    PAGE_DELETE_FAILURE: 'PAGE_DELETE_FAILURE',
    PAGE_DELETE_REQUEST: 'PAGE_DELETE_REQUEST',
    PAGE_DELETE_REQUEST_REQUESTING: 'PAGE_DELETE_REQUEST_REQUESTING',
    PAGE_DELETE_REQUEST_SUCCESS: 'PAGE_DELETE_REQUEST_SUCCESS',
    PAGE_DELETE_REQUEST_ERROR: 'PAGE_DELETE_REQUEST_ERROR'
}

export function pageReset() {
    return {
        type: actionTypes.PAGE_RESET
    }
}

export function pageListFailure(internalError) {
    return {
        type: actionTypes.PAGE_LIST_FAILURE,
        internalError
    }
}

export function pageList(language) {
    return {
        type: actionTypes.PAGE_LIST_REQUEST,
        payload: {
            language
        }
    }
}

export function pageListRequesting() {
    return {
        type: actionTypes.PAGE_LIST_REQUEST_REQUESTING
    }
}

export function pageListSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_LIST_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function pageListError(errors) {
    return {
        type: actionTypes.PAGE_LIST_REQUEST_ERROR,
        errors
    }
}

/// CREATE

export function pageCreateFailure(internalError) {
    return {
        type: actionTypes.PAGE_CREATE_FAILURE,
        internalError
    }
}

export function pageCreate(title, slug, sections, language) {
    return {
        type: actionTypes.PAGE_CREATE_REQUEST,
        payload: {
            title,
            slug,
            sections,
            language
        }
    }
}

export function pageCreateRequesting() {
    return {
        type: actionTypes.PAGE_CREATE_REQUEST_REQUESTING
    }
}

export function pageCreateSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_CREATE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function pageCreateError(errors) {
    return {
        type: actionTypes.PAGE_CREATE_REQUEST_ERROR,
        errors
    }
}

/// DELETE

export function pageDeleteFailure(internalError) {
    return {
        type: actionTypes.PAGE_DELETE_FAILURE,
        internalError
    }
}

export function pageDelete(id) {
    return {
        type: actionTypes.PAGE_DELETE_REQUEST,
        payload: {
           id
        }
    }
}

export function pageDeleteRequesting() {
    return {
        type: actionTypes.PAGE_DELETE_REQUEST_REQUESTING
    }
}

export function pageDeleteSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_DELETE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function pageDeleteError(errors) {
    return {
        type: actionTypes.PAGE_DELETE_REQUEST_ERROR,
        errors
    }
}