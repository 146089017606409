import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api, { getCurrentUserId } from '../../../api';
import { actionTypes, pageCreateError, pageCreateFailure, pageCreateRequesting, pageCreateSuccess, pageListError, pageListFailure, pageListRequesting, pageListSuccess, pageDeleteRequesting, pageDeleteSuccess, pageDeleteError, pageDeleteFailure } from './actions';
import { toast } from 'react-toastify';

function* pageList(action) {
    try {
        yield put(pageListRequesting());

        const language = action.payload.language;

        const mutation = gql`
            query {
                posts(where: {
                    type: PAGE
                    language: {
                        id: "${language}"
                    }
                }) {
                    id
                    type
                    title
                    slug
                    published
                    publishedDate
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.posts) {
            yield put(pageListSuccess(data.posts, errors));
        } else {
            yield put(pageListError(errors));
        }
    } catch (internalError) {
        yield put(pageListFailure(internalError));
    }
}

function* pageCreate(action) {
    try {
        yield put(pageCreateRequesting());

        const title = action.payload.title;
        const slug = action.payload.slug;
        const sections = action.payload.sections;
        const language = action.payload.language;

        const mutation = `
            mutation {
                createPost(
                    type: PAGE
                    language: { connect: { id: "${language}" } }
                    title: "${title}"
                    excerpt: ""
                    slug: "${slug}"
                    key: ""
                    metatags: {}
                    categories: { connect: [] }
                    tags: { set: [] }
                    published: false
                    publishedDate: "2017-09-01T10:00:00.000Z"
                    author: { connect: { id: "${getCurrentUserId()}" } }
                    sections: {
                        create: [
                            ${sections.map((section) => `
                                {
                                    id: "${section.id}"
                                }
                            `)}
                        ]
                    }
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createPost) {
            toast.success("Created successfully!");
            yield put(pageCreateSuccess(data.createPost, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(pageCreateError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(pageCreateFailure(internalError));
    }
}

function* pageDelete(action) {
    try {
        yield put(pageDeleteRequesting());

        const id = action.payload.id;

        const mutation = `
            mutation {
                deletePost(
                    id: "${id}"
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.deletePost) {
            toast.success("Deleted successfully!");
            yield put(pageDeleteSuccess(data.deletePost, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(pageDeleteError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(pageDeleteFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.PAGE_LIST_REQUEST, pageList),
        takeLatest(actionTypes.PAGE_CREATE_REQUEST, pageCreate),
        takeLatest(actionTypes.PAGE_DELETE_REQUEST, pageDelete)
    ])
}

export default sagas
