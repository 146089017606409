import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api, { getCurrentUserId } from '../../../api';
import { actionTypes, newsCreateError, newsCreateFailure, newsCreateRequesting, newsCreateSuccess, newsListError, newsListFailure, newsListRequesting, newsListSuccess, newsDeleteRequesting, newsDeleteSuccess, newsDeleteError, newsDeleteFailure } from './actions';
import { toast } from 'react-toastify';

function* newsList(action) {
    try {
        yield put(newsListRequesting());

        const language = action.payload.language;
        const first = action.payload.first;
        const after = action.payload.after;
        const join = action.payload.join;

        const mutation = gql`
            query {
                posts(
                    where: {
                        type: NEWS
                        language: {
                            id: "${language}"
                        }
                    }
                    first: ${first}
                    ${after ? `after: "${after}"` : ``}
                    orderBy: publishedDate_DESC
                ) {
                    id
                    type
                    title
                    slug
                    published
                    publishedDate
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.posts) {
            yield put(newsListSuccess(data.posts, join));
        } else {
            yield put(newsListError(errors));
        }
    } catch (internalError) {
        yield put(newsListFailure(internalError));
    }
}

function* newsCreate(action) {
    try {
        yield put(newsCreateRequesting());

        const title = action.payload.title;
        const slug = action.payload.slug;
        const language = action.payload.language;

        const mutation = `
            mutation {
                createPost(
                    type: NEWS
                    key: ""
                    language: { connect: { id: "${language}" } }
                    title: "${title}"
                    excerpt: ""
                    slug: "${slug}"
                    metatags: {}
                    categories: { connect: [] }
                    tags: { set: [] }
                    published: false
                    publishedDate: "2019-01-01T10:00:00.000Z"
                    author: { connect: { id: "${getCurrentUserId()}" } }
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createPost) {
            toast.success("Created successfully!");
            yield put(newsCreateSuccess(data.createPost, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(newsCreateError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(newsCreateFailure(internalError));
    }
}

function* newsDelete(action) {
    try {
        yield put(newsDeleteRequesting());

        const id = action.payload.id;

        const mutation = `
            mutation {
                deletePost(
                    id: "${id}"
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.deletePost) {
            toast.success("Deleted successfully!");
            yield put(newsDeleteSuccess(data.deletePost, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(newsDeleteError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(newsDeleteFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.NEWS_LIST_REQUEST, newsList),
        takeLatest(actionTypes.NEWS_CREATE_REQUEST, newsCreate),
        takeLatest(actionTypes.NEWS_DELETE_REQUEST, newsDelete)
    ])
}

export default sagas
