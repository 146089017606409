export const actionTypes = {
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_REQUEST_REQUESTING: 'LOGIN_REQUEST_REQUESTING',
    LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
    LOGIN_REQUEST_ERROR: 'LOGIN_REQUEST_ERROR',
    LOGOU_REQUEST: 'LOGOU_REQUEST'
}

export function logout() {
    return {
        type: actionTypes.LOGOU_REQUEST,
        payload: {
        }
    }
}

export function loginFailure(internalError) {
    return {
        type: actionTypes.LOGIN_FAILURE,
        internalError
    }
}

export function login(email, password) {
    return {
        type: actionTypes.LOGIN_REQUEST,
        payload: {
            email,
            password
        }
    }
}

export function loginRequesting() {
    return {
        type: actionTypes.LOGIN_REQUEST_REQUESTING
    }
}

export function loginSuccess(data, errors) {
    return {
        type: actionTypes.LOGIN_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function loginError(errors) {
    return {
        type: actionTypes.LOGIN_REQUEST_ERROR,
        errors
    }
}