export const actionTypes = {
    NEWS_EDIT_RESET: 'NEWS_EDIT_RESET',
    NEWS_GET_FAILURE: 'NEWS_GET_FAILURE',
    NEWS_GET_REQUEST: 'NEWS_GET_REQUEST',
    NEWS_GET_REQUEST_REQUESTING: 'NEWS_GET_REQUEST_REQUESTING',
    NEWS_GET_REQUEST_SUCCESS: 'NEWS_GET_REQUEST_SUCCESS',
    NEWS_GET_REQUEST_ERROR: 'NEWS_GET_REQUEST_ERROR',
    NEWS_EDIT_FAILURE: 'NEWS_EDIT_FAILURE',
    NEWS_EDIT_REQUEST: 'NEWS_EDIT_REQUEST',
    NEWS_EDIT_REQUEST_REQUESTING: 'NEWS_EDIT_REQUEST_REQUESTING',
    NEWS_EDIT_REQUEST_SUCCESS: 'NEWS_EDIT_REQUEST_SUCCESS',
    NEWS_EDIT_REQUEST_ERROR: 'NEWS_EDIT_REQUEST_ERROR',
    NEWS_SECTION_SAVE_FAILURE: 'NEWS_SECTION_SAVE_FAILURE',
    NEWS_SECTION_SAVE_REQUEST: 'NEWS_SECTION_SAVE_REQUEST',
    NEWS_SECTION_SAVE_REQUEST_REQUESTING: 'NEWS_SECTION_SAVE_REQUEST_REQUESTING',
    NEWS_SECTION_SAVE_REQUEST_SUCCESS: 'NEWS_SECTION_SAVE_REQUEST_SUCCESS',
    NEWS_SECTION_SAVE_REQUEST_ERROR: 'NEWS_SECTION_SAVE_REQUEST_ERROR',
    NEWS_SAVE_FAILURE: 'NEWS_SAVE_FAILURE',
    NEWS_SAVE_REQUEST: 'NEWS_SAVE_REQUEST',
    NEWS_SAVE_REQUEST_REQUESTING: 'NEWS_SAVE_REQUEST_REQUESTING',
    NEWS_SAVE_REQUEST_SUCCESS: 'NEWS_SAVE_REQUEST_SUCCESS',
    NEWS_SAVE_REQUEST_ERROR: 'NEWS_SAVE_REQUEST_ERROR'
}

export function newsReset() {
    return {
        type: actionTypes.NEWS_EDIT_RESET
    }
}

//////////////// GET

export function newsGetFailure(internalError) {
    return {
        type: actionTypes.NEWS_GET_FAILURE,
        internalError
    }
}

export function newsGet(id) {
    return {
        type: actionTypes.NEWS_GET_REQUEST,
        payload: {
            id
        }
    }
}

export function newsGetRequesting() {
    return {
        type: actionTypes.NEWS_GET_REQUEST_REQUESTING
    }
}

export function newsGetSuccess(data, errors) {
    return {
        type: actionTypes.NEWS_GET_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function newsGetError(errors) {
    return {
        type: actionTypes.NEWS_GET_REQUEST_ERROR,
        errors
    }
}


//////////////// EDIT

export function newsEditFailure(internalError) {
    return {
        type: actionTypes.NEWS_EDIT_FAILURE,
        internalError
    }
}

export function newsEdit(id, title, key, structure) {
    return {
        type: actionTypes.NEWS_EDIT_REQUEST,
        payload: {
            id,
            title,
            key,
            structure
        }
    }
}

export function newsEditRequesting() {
    return {
        type: actionTypes.NEWS_EDIT_REQUEST_REQUESTING
    }
}

export function newsEditSuccess(data, errors) {
    return {
        type: actionTypes.NEWS_EDIT_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function newsEditError(errors) {
    return {
        type: actionTypes.NEWS_EDIT_REQUEST_ERROR,
        errors
    }
}

//////////////// SAVE SECTION

export function newsSectionSaveFailure(internalError) {
    return {
        type: actionTypes.NEWS_SECTION_SAVE_FAILURE,
        internalError
    }
}

export function newsSectionSave(id, data) {
    return {
        type: actionTypes.NEWS_SECTION_SAVE_REQUEST,
        payload: {
            id,
            data
        }
    }
}

export function newsSectionSaveRequesting() {
    return {
        type: actionTypes.NEWS_SECTION_SAVE_REQUEST_REQUESTING
    }
}

export function newsSectionSaveSuccess(data, errors) {
    return {
        type: actionTypes.NEWS_SECTION_SAVE_REQUEST_SUCCESS,
        errors
    }
}

export function newsSectionSaveError(errors) {
    return {
        type: actionTypes.NEWS_SECTION_SAVE_REQUEST_ERROR,
        errors
    }
}


//////////////// SAVE Page

export function newsSaveFailure(internalError) {
    return {
        type: actionTypes.NEWS_SAVE_FAILURE,
        internalError
    }
}

export function newsSave(id, key, title, slug, excerpt, thumbnail, featured, published, metatags, publishedDate, tags, categories) {
    return {
        type: actionTypes.NEWS_SAVE_REQUEST,
        payload: {
            id,
            key, title, slug, excerpt, thumbnail, featured, published, metatags, publishedDate, tags, categories
        }
    }
}

export function newsSaveRequesting() {
    return {
        type: actionTypes.NEWS_SAVE_REQUEST_REQUESTING
    }
}

export function newsSaveSuccess(data, errors) {
    return {
        type: actionTypes.NEWS_SAVE_REQUEST_SUCCESS,
        errors
    }
}

export function newsSaveError(errors) {
    return {
        type: actionTypes.NEWS_SAVE_REQUEST_ERROR,
        errors
    }
}