export const actionTypes = {
    SECTION_TEMPLATE_RESET: 'SECTION_TEMPLATE_RESET',
    SECTION_TEMPLATE_GET_FAILURE: 'SECTION_TEMPLATE_GET_FAILURE',
    SECTION_TEMPLATE_GET_REQUEST: 'SECTION_TEMPLATE_GET_REQUEST',
    SECTION_TEMPLATE_GET_REQUEST_REQUESTING: 'SECTION_TEMPLATE_GET_REQUEST_REQUESTING',
    SECTION_TEMPLATE_GET_REQUEST_SUCCESS: 'SECTION_TEMPLATE_GET_REQUEST_SUCCESS',
    SECTION_TEMPLATE_GET_REQUEST_ERROR: 'SECTION_TEMPLATE_GET_REQUEST_ERROR',
    SECTION_TEMPLATE_EDIT_FAILURE: 'SECTION_TEMPLATE_EDIT_FAILURE',
    SECTION_TEMPLATE_EDIT_REQUEST: 'SECTION_TEMPLATE_EDIT_REQUEST',
    SECTION_TEMPLATE_EDIT_REQUEST_REQUESTING: 'SECTION_TEMPLATE_EDIT_REQUEST_REQUESTING',
    SECTION_TEMPLATE_EDIT_REQUEST_SUCCESS: 'SECTION_TEMPLATE_EDIT_REQUEST_SUCCESS',
    SECTION_TEMPLATE_EDIT_REQUEST_ERROR: 'SECTION_TEMPLATE_EDIT_REQUEST_ERROR',
    SECTION_TEMPLATE_CREATE_FAILURE: 'SECTION_TEMPLATE_CREATE_FAILURE',
    SECTION_TEMPLATE_CREATE_REQUEST: 'SECTION_TEMPLATE_CREATE_REQUEST',
    SECTION_TEMPLATE_CREATE_REQUEST_REQUESTING: 'SECTION_TEMPLATE_CREATE_REQUEST_REQUESTING',
    SECTION_TEMPLATE_CREATE_REQUEST_SUCCESS: 'SECTION_TEMPLATE_CREATE_REQUEST_SUCCESS',
    SECTION_TEMPLATE_CREATE_REQUEST_ERROR: 'SECTION_TEMPLATE_CREATE_REQUEST_ERROR'
}

export function sectionTemplateReset() {
    return {
        type: actionTypes.SECTION_TEMPLATE_RESET,
        payload: {

        }
    }
}

//////////////// GET

export function sectionTemplateGetFailure(internalError) {
    return {
        type: actionTypes.SECTION_TEMPLATE_GET_FAILURE,
        internalError
    }
}

export function sectionTemplateGet(id) {
    return {
        type: actionTypes.SECTION_TEMPLATE_GET_REQUEST,
        payload: {
            id
        }
    }
}

export function sectionTemplateGetRequesting() {
    return {
        type: actionTypes.SECTION_TEMPLATE_GET_REQUEST_REQUESTING
    }
}

export function sectionTemplateGetSuccess(data, errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_GET_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function sectionTemplateGetError(errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_GET_REQUEST_ERROR,
        errors
    }
}


//////////////// EDIT

export function sectionTemplateEditFailure(internalError) {
    return {
        type: actionTypes.SECTION_TEMPLATE_EDIT_FAILURE,
        internalError
    }
}

export function sectionTemplateEdit(id, title, key, structure) {
    return {
        type: actionTypes.SECTION_TEMPLATE_EDIT_REQUEST,
        payload: {
            id,
            title,
            key,
            structure
        }
    }
}

export function sectionTemplateEditRequesting() {
    return {
        type: actionTypes.SECTION_TEMPLATE_EDIT_REQUEST_REQUESTING
    }
}

export function sectionTemplateEditSuccess(data, errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_EDIT_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function sectionTemplateEditError(errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_EDIT_REQUEST_ERROR,
        errors
    }
}

//////////////// CREATE

export function sectionTemplateCreateFailure(internalError) {
    return {
        type: actionTypes.SECTION_TEMPLATE_CREATE_FAILURE,
        internalError
    }
}

export function sectionTemplateCreate(title, key, structure) {
    return {
        type: actionTypes.SECTION_TEMPLATE_CREATE_REQUEST,
        payload: {
            title,
            key,
            structure
        }
    }
}

export function sectionTemplateCreateRequesting() {
    return {
        type: actionTypes.SECTION_TEMPLATE_CREATE_REQUEST_REQUESTING
    }
}

export function sectionTemplateCreateSuccess(data, errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_CREATE_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function sectionTemplateCreateError(errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_CREATE_REQUEST_ERROR,
        errors
    }
}