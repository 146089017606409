import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { actionTypes, sectionTemplateEditError, sectionTemplateEditFailure, sectionTemplateEditRequesting, sectionTemplateEditSuccess, sectionTemplateGetRequesting, sectionTemplateGetSuccess, sectionTemplateGetError, sectionTemplateGetFailure, sectionTemplateCreateRequesting, sectionTemplateCreateSuccess, sectionTemplateCreateError, sectionTemplateCreateFailure } from './actions';

function* sectionTemplateGet(action) {
    try {
        yield put(sectionTemplateGetRequesting());

        const id = action.payload.id;

        const mutation = `
            query {
                sectionTemplate(id: "${id}") {
                    id
                    title
                    key
                    structure
                }
            }          
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.sectionTemplate) {
            yield put(sectionTemplateGetSuccess(data.sectionTemplate, errors));
        } else {
            yield put(sectionTemplateGetError(errors));
        }
    } catch (internalError) {
        yield put(sectionTemplateGetFailure(internalError));
    }
}

function* sectionTemplateEdit(action) {
    try {
        yield put(sectionTemplateEditRequesting());

        const id = action.payload.id;
        const title = action.payload.title;
        const key = action.payload.key;
        const structure = action.payload.structure;

        const mutation = `
            mutation {
                updateSectionTemplate(
                    id: "${id}"
                    title: "${title}"
                    key: "${key}"
                    structure: ${structure}
                ) {
                    id
                    title
                    key
                    structure
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateSectionTemplate) {
            yield put(sectionTemplateEditSuccess(data.updateSectionTemplate, errors));
        } else {
            yield put(sectionTemplateEditError(errors));
        }
    } catch (internalError) {
        yield put(sectionTemplateEditFailure(internalError));
    }
}

function* sectionTemplateCreate(action) {
    try {
        yield put(sectionTemplateCreateRequesting());

        const title = action.payload.title;
        const key = action.payload.key;
        const structure = action.payload.structure;

        const mutation = `
            mutation {
                createSectionTemplate(
                    title: "${title}"
                    key: "${key}"
                    structure: ${structure}
                ) {
                    id
                    title
                    key
                    structure
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createSectionTemplate) {
            yield put(sectionTemplateCreateSuccess(data.createSectionTemplate, errors));
        } else {
            yield put(sectionTemplateCreateError(errors));
        }
    } catch (internalError) {
        yield put(sectionTemplateCreateFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.SECTION_TEMPLATE_CREATE_REQUEST, sectionTemplateCreate),
        takeLatest(actionTypes.SECTION_TEMPLATE_EDIT_REQUEST, sectionTemplateEdit),
        takeLatest(actionTypes.SECTION_TEMPLATE_GET_REQUEST, sectionTemplateGet)
    ])
}

export default sagas
