import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { actionTypes, categoryCreateError, categoryCreateFailure, categoryCreateRequesting, categoryCreateSuccess, categoryDeleteError, categoryDeleteFailure, categoryDeleteRequesting, categoryDeleteSuccess, categoryEditError, categoryEditFailure, categoryEditRequesting, categoryEditSuccess, categoryListError, categoryListFailure, categoryListRequesting, categoryListSuccess } from './actions';
import { toast } from 'react-toastify';

function* categoryList(action) {
    try {
        yield put(categoryListRequesting());

        const language = action.payload.language;

        const mutation = gql`
            query {
                categories(where: {
                    language: {
                        id: "${language}"
                    }
                }) {
                    id
                    title
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.categories) {
            yield put(categoryListSuccess(data.categories, errors));
        } else {
            yield put(categoryListError(errors));
        }
    } catch (internalError) {
        yield put(categoryListFailure(internalError));
    }
}

function* categoryCreate(action) {
    try {
        yield put(categoryCreateRequesting());

        const title = action.payload.title;
        const language = action.payload.language;

        const mutation = `
            mutation {
                createCategory(
                    title: "${title}"
                    language: {
                        connect: {
                            id: "${language}"
                        }
                    }
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createCategory) {
            toast.success("Saved successfully!");
            yield put(categoryCreateSuccess(data.createCategory, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(categoryCreateError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(categoryCreateFailure(internalError));
    }
}

function* categoryDelete(action) {
    try {
        yield put(categoryDeleteRequesting());

        const id = action.payload.id;

        const mutation = `
            mutation {
                deleteCategory(
                    id: "${id}"
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);

        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.deleteCategory) {
            toast.success("Deleted successfully!");
            yield put(categoryDeleteSuccess(data.deleteCategory, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(categoryDeleteError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(categoryDeleteFailure(internalError));
    }
}

function* categoryEdit(action) {
    try {
        yield put(categoryEditRequesting());

        const id = action.payload.id;
        const title = action.payload.title;

        const mutation = `
            mutation {
                updateCategory(
                    id: "${id}"
                    title: "${title}"
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateCategory) {
            toast.success("Saved successfully!");
            yield put(categoryEditSuccess(data.updateCategory, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(categoryEditError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(categoryEditFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.CATEGORY_LIST_REQUEST, categoryList),
        takeLatest(actionTypes.CATEGORY_CREATE_REQUEST, categoryCreate),
        takeLatest(actionTypes.CATEGORY_DELETE_REQUEST, categoryDelete),
        takeLatest(actionTypes.CATEGORY_EDIT_REQUEST, categoryEdit)
    ])
}

export default sagas
