export const actionTypes = {
    USER_RESET: 'USER_RESET',
    USER_LIST_FAILURE: 'USER_LIST_FAILURE',
    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    USER_LIST_REQUEST_REQUESTING: 'USER_LIST_REQUEST_REQUESTING',
    USER_LIST_REQUEST_SUCCESS: 'USER_LIST_REQUEST_SUCCESS',
    USER_LIST_REQUEST_ERROR: 'USER_LIST_REQUEST_ERROR',
    USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',
    USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
    USER_CREATE_REQUEST_REQUESTING: 'USER_CREATE_REQUEST_REQUESTING',
    USER_CREATE_REQUEST_SUCCESS: 'USER_CREATE_REQUEST_SUCCESS',
    USER_CREATE_REQUEST_ERROR: 'USER_CREATE_REQUEST_ERROR',
    USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_REQUEST_REQUESTING: 'USER_DELETE_REQUEST_REQUESTING',
    USER_DELETE_REQUEST_SUCCESS: 'USER_DELETE_REQUEST_SUCCESS',
    USER_DELETE_REQUEST_ERROR: 'USER_DELETE_REQUEST_ERROR',
    USER_EDIT_FAILURE: 'USER_EDIT_FAILURE',
    USER_EDIT_REQUEST: 'USER_EDIT_REQUEST',
    USER_EDIT_REQUEST_REQUESTING: 'USER_EDIT_REQUEST_REQUESTING',
    USER_EDIT_REQUEST_SUCCESS: 'USER_EDIT_REQUEST_SUCCESS',
    USER_EDIT_REQUEST_ERROR: 'USER_EDIT_REQUEST_ERROR'
}

export function userReset() {
    return {
        type: actionTypes.USER_RESET
    }
}

export function userListFailure(internalError) {
    return {
        type: actionTypes.USER_LIST_FAILURE,
        internalError
    }
}

export function userList() {
    return {
        type: actionTypes.USER_LIST_REQUEST,
        payload: {
        }
    }
}

export function userListRequesting() {
    return {
        type: actionTypes.USER_LIST_REQUEST_REQUESTING
    }
}

export function userListSuccess(data, errors) {
    return {
        type: actionTypes.USER_LIST_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function userListError(errors) {
    return {
        type: actionTypes.USER_LIST_REQUEST_ERROR,
        errors
    }
}

/// CREATE

export function userCreateFailure(internalError) {
    return {
        type: actionTypes.USER_CREATE_FAILURE,
        internalError
    }
}

export function userCreate(name, email, password, role) {
    return {
        type: actionTypes.USER_CREATE_REQUEST,
        payload: {
            name, email, password, role
        }
    }
}

export function userCreateRequesting() {
    return {
        type: actionTypes.USER_CREATE_REQUEST_REQUESTING
    }
}

export function userCreateSuccess(data, errors) {
    return {
        type: actionTypes.USER_CREATE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function userCreateError(errors) {
    return {
        type: actionTypes.USER_CREATE_REQUEST_ERROR,
        errors
    }
}

/// DELETE

export function userDeleteFailure(internalError) {
    return {
        type: actionTypes.USER_DELETE_FAILURE,
        internalError
    }
}

export function userDelete(id) {
    return {
        type: actionTypes.USER_DELETE_REQUEST,
        payload: {
           id
        }
    }
}

export function userDeleteRequesting() {
    return {
        type: actionTypes.USER_DELETE_REQUEST_REQUESTING
    }
}

export function userDeleteSuccess(data, errors) {
    return {
        type: actionTypes.USER_DELETE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function userDeleteError(errors) {
    return {
        type: actionTypes.USER_DELETE_REQUEST_ERROR,
        errors
    }
}

//////////////// EDIT

export function userEditFailure(internalError) {
    return {
        type: actionTypes.USER_EDIT_FAILURE,
        internalError
    }
}

export function userEdit(id, name, email, password, role) {
    return {
        type: actionTypes.USER_EDIT_REQUEST,
        payload: {
            id,
            name, email, password, role
        }
    }
}

export function userEditRequesting() {
    return {
        type: actionTypes.USER_EDIT_REQUEST_REQUESTING
    }
}

export function userEditSuccess(data, errors) {
    return {
        type: actionTypes.USER_EDIT_REQUEST_SUCCESS,
        errors
    }
}

export function userEditError(errors) {
    return {
        type: actionTypes.USER_EDIT_REQUEST_ERROR,
        errors
    }
}