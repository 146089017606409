export const actionTypes = {
    PAGE_EDIT_RESET: 'PAGE_EDIT_RESET',
    PAGE_GET_FAILURE: 'PAGE_GET_FAILURE',
    PAGE_GET_REQUEST: 'PAGE_GET_REQUEST',
    PAGE_GET_REQUEST_REQUESTING: 'PAGE_GET_REQUEST_REQUESTING',
    PAGE_GET_REQUEST_SUCCESS: 'PAGE_GET_REQUEST_SUCCESS',
    PAGE_GET_REQUEST_ERROR: 'PAGE_GET_REQUEST_ERROR',
    PAGE_EDIT_FAILURE: 'PAGE_EDIT_FAILURE',
    PAGE_EDIT_REQUEST: 'PAGE_EDIT_REQUEST',
    PAGE_EDIT_REQUEST_REQUESTING: 'PAGE_EDIT_REQUEST_REQUESTING',
    PAGE_EDIT_REQUEST_SUCCESS: 'PAGE_EDIT_REQUEST_SUCCESS',
    PAGE_EDIT_REQUEST_ERROR: 'PAGE_EDIT_REQUEST_ERROR',
    PAGE_SECTION_SAVE_FAILURE: 'PAGE_SECTION_SAVE_FAILURE',
    PAGE_SECTION_SAVE_REQUEST: 'PAGE_SECTION_SAVE_REQUEST',
    PAGE_SECTION_SAVE_REQUEST_REQUESTING: 'PAGE_SECTION_SAVE_REQUEST_REQUESTING',
    PAGE_SECTION_SAVE_REQUEST_SUCCESS: 'PAGE_SECTION_SAVE_REQUEST_SUCCESS',
    PAGE_SECTION_SAVE_REQUEST_ERROR: 'PAGE_SECTION_SAVE_REQUEST_ERROR',
    PAGE_SAVE_FAILURE: 'PAGE_SAVE_FAILURE',
    PAGE_SAVE_REQUEST: 'PAGE_SAVE_REQUEST',
    PAGE_SAVE_REQUEST_REQUESTING: 'PAGE_SAVE_REQUEST_REQUESTING',
    PAGE_SAVE_REQUEST_SUCCESS: 'PAGE_SAVE_REQUEST_SUCCESS',
    PAGE_SAVE_REQUEST_ERROR: 'PAGE_SAVE_REQUEST_ERROR'
}

export function pageReset() {
    return {
        type: actionTypes.PAGE_EDIT_RESET
    }
}

//////////////// GET

export function pageGetFailure(internalError) {
    return {
        type: actionTypes.PAGE_GET_FAILURE,
        internalError
    }
}

export function pageGet(id) {
    return {
        type: actionTypes.PAGE_GET_REQUEST,
        payload: {
            id
        }
    }
}

export function pageGetRequesting() {
    return {
        type: actionTypes.PAGE_GET_REQUEST_REQUESTING
    }
}

export function pageGetSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_GET_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function pageGetError(errors) {
    return {
        type: actionTypes.PAGE_GET_REQUEST_ERROR,
        errors
    }
}


//////////////// EDIT

export function pageEditFailure(internalError) {
    return {
        type: actionTypes.PAGE_EDIT_FAILURE,
        internalError
    }
}

export function pageEdit(id, title, key, structure) {
    return {
        type: actionTypes.PAGE_EDIT_REQUEST,
        payload: {
            id,
            title,
            key,
            structure
        }
    }
}

export function pageEditRequesting() {
    return {
        type: actionTypes.PAGE_EDIT_REQUEST_REQUESTING
    }
}

export function pageEditSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_EDIT_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function pageEditError(errors) {
    return {
        type: actionTypes.PAGE_EDIT_REQUEST_ERROR,
        errors
    }
}

//////////////// SAVE SECTION

export function pageSectionSaveFailure(internalError) {
    return {
        type: actionTypes.PAGE_SECTION_SAVE_FAILURE,
        internalError
    }
}

export function pageSectionSave(id, data) {
    return {
        type: actionTypes.PAGE_SECTION_SAVE_REQUEST,
        payload: {
            id,
            data
        }
    }
}

export function pageSectionSaveRequesting() {
    return {
        type: actionTypes.PAGE_SECTION_SAVE_REQUEST_REQUESTING
    }
}

export function pageSectionSaveSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_SECTION_SAVE_REQUEST_SUCCESS,
        errors
    }
}

export function pageSectionSaveError(errors) {
    return {
        type: actionTypes.PAGE_SECTION_SAVE_REQUEST_ERROR,
        errors
    }
}


//////////////// SAVE Page

export function pageSaveFailure(internalError) {
    return {
        type: actionTypes.PAGE_SAVE_FAILURE,
        internalError
    }
}

export function pageSave(id, key, title, slug, excerpt, published, metatags, publishedDate, tags, categories) {
    return {
        type: actionTypes.PAGE_SAVE_REQUEST,
        payload: {
            id,
            key, title, slug, excerpt, published, metatags, publishedDate, tags, categories
        }
    }
}

export function pageSaveRequesting() {
    return {
        type: actionTypes.PAGE_SAVE_REQUEST_REQUESTING
    }
}

export function pageSaveSuccess(data, errors) {
    return {
        type: actionTypes.PAGE_SAVE_REQUEST_SUCCESS,
        errors
    }
}

export function pageSaveError(errors) {
    return {
        type: actionTypes.PAGE_SAVE_REQUEST_ERROR,
        errors
    }
}