import { actionTypes } from './actions';

export const initialState = {
    data: [],
    errors: {},
    internalError: undefined,
    type: undefined
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.USER_LIST_FAILURE:
        case actionTypes.USER_LIST_REQUEST_REQUESTING:
        case actionTypes.USER_LIST_REQUEST_SUCCESS:
        case actionTypes.USER_LIST_REQUEST_ERROR:
        case actionTypes.USER_CREATE_FAILURE:
        case actionTypes.USER_CREATE_REQUEST_REQUESTING:
        case actionTypes.USER_CREATE_REQUEST_SUCCESS:
        case actionTypes.USER_CREATE_REQUEST_ERROR:
        case actionTypes.USER_DELETE_FAILURE:
        case actionTypes.USER_DELETE_REQUEST_REQUESTING:
        case actionTypes.USER_DELETE_REQUEST_SUCCESS:
        case actionTypes.USER_DELETE_REQUEST_ERROR:
        case actionTypes.USER_EDIT_FAILURE:
        case actionTypes.USER_EDIT_REQUEST_REQUESTING:
        case actionTypes.USER_EDIT_REQUEST_SUCCESS:
        case actionTypes.USER_EDIT_REQUEST_ERROR:
        case actionTypes.USER_RESET:
            return {
                ...state,
                ...action
            }
        default:
            return state
    }
}

export default reducer
