import { actionTypes } from './actions';

export const initialState = {
    data: {},
    errors: {},
    internalError: undefined,
    type: undefined
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SECTION_TEMPLATE_GET_FAILURE:
        case actionTypes.SECTION_TEMPLATE_GET_REQUEST_REQUESTING:
        case actionTypes.SECTION_TEMPLATE_GET_REQUEST_SUCCESS:
        case actionTypes.SECTION_TEMPLATE_GET_REQUEST_ERROR:
        case actionTypes.SECTION_TEMPLATE_EDIT_FAILURE:
        case actionTypes.SECTION_TEMPLATE_EDIT_REQUEST_REQUESTING:
        case actionTypes.SECTION_TEMPLATE_EDIT_REQUEST_SUCCESS:
        case actionTypes.SECTION_TEMPLATE_EDIT_REQUEST_ERROR:
        case actionTypes.SECTION_TEMPLATE_CREATE_FAILURE:
        case actionTypes.SECTION_TEMPLATE_CREATE_REQUEST_REQUESTING:
        case actionTypes.SECTION_TEMPLATE_CREATE_REQUEST_SUCCESS:
        case actionTypes.SECTION_TEMPLATE_CREATE_REQUEST_ERROR:
            return {
                ...state,
                ...action
            }
        case actionTypes.SECTION_TEMPLATE_RESET:
            return {
                data: {},
                errors: {},
                internalError: undefined,
                type: undefined
            }
        default:
            return state
    }
}

export default reducer
