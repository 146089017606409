import { actionTypes } from './actions';

export const initialState = {
    data: {},
    errors: {},
    internalError: undefined,
    type: undefined
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ME_UPDATE_FAILURE:
        case actionTypes.ME_UPDATE_REQUEST_REQUESTING:
        case actionTypes.ME_UPDATE_REQUEST_SUCCESS:
        case actionTypes.ME_UPDATE_REQUEST_ERROR:
            return {
                ...state,
                ...action
            }
        case actionTypes.ME_RESET:
            return {
                data: {},
                errors: {},
                internalError: undefined,
                type: undefined
            }
        default:
            return state
    }
}

export default reducer
