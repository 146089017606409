import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { actionTypes, pageEditError, pageEditFailure, pageEditRequesting, pageEditSuccess, pageGetError, pageGetFailure, pageGetRequesting, pageGetSuccess, pageSectionSaveRequesting, pageSectionSaveSuccess, pageSectionSaveError, pageSectionSaveFailure, pageSaveRequesting, pageSaveSuccess, pageSaveError, pageSaveFailure } from './actions';
import { toast } from 'react-toastify';

function* pageGet(action) {
    try {
        yield put(pageGetRequesting());

        const id = action.payload.id;

        const mutation = `
            query {
                categories {
                    id
                    title
                    language {
                        id
                    }
                }
                post(id: "${id}") {
                    id
                    key
                    type
                    published
                    title
                    excerpt
                    slug
                    metatags
                    publishedDate
                    tags
                    categories {
                        id
                        title
                    }
                    language {
                        id
                        name
                        slug
                    }
                    sections {
                        id
                        template {
                            id
                            title
                            key
                            structure
                        }
                        position
                        data
                    }
                }
            }          
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data) {
            yield put(pageGetSuccess(data, errors));
        } else {
            yield put(pageGetError(errors));
        }
    } catch (internalError) {
        yield put(pageGetFailure(internalError));
    }
}

function* pageEdit(action) {
    try {
        yield put(pageEditRequesting());

        const id = action.payload.id;
        const title = action.payload.title;
        const key = action.payload.key;
        const structure = action.payload.structure;

        const mutation = `
            mutation {
                updateSectionTemplate(
                    id: "${id}"
                    title: "${title}"
                    key: "${key}"
                    structure: ${structure}
                ) {
                    id
                    title
                    key
                    structure
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateSectionTemplate) {
            yield put(pageEditSuccess(data.updateSectionTemplate, errors));
        } else {
            yield put(pageEditError(errors));
        }
    } catch (internalError) {
        yield put(pageEditFailure(internalError));
    }
}

function* pageSectionSave(action) {
    try {
        yield put(pageSectionSaveRequesting());

        const id = action.payload.id;
        const sectionData = action.payload.data;

        const mutation = `
            mutation {
                updateSection(
                    id: "${id}"
                    data: ${JSON.stringify(sectionData).replace(/"([^(")"]+)":/g, "$1:")}
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateSection) {
            toast.success("Saved successfully!");
            yield put(pageSectionSaveSuccess(data.updateSection, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(pageSectionSaveError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(pageSectionSaveFailure(internalError));
    }
}

function* pageSave(action) {
    try {
        yield put(pageSaveRequesting());

        const id = action.payload.id;
        const key = action.payload.key;
        const title = action.payload.title;
        const slug = action.payload.slug;
        const excerpt = action.payload.excerpt;
        const published = action.payload.published;
        const metatags = action.payload.metatags;
        const publishedDate = action.payload.publishedDate;
        const tags = "[" + action.payload.tags.split(",").map((tag) => "\"" + tag + "\"") + "]";
        const categories = action.payload.categories;

        const mutation = `
            mutation {
                updatePost(
                    id: "${id}"
                    key: "${key}"
                    title: "${title}"
                    excerpt: "${excerpt}"
                    thumbnail: {}
                    slug: "${slug}"
                    featured: false,
                    metatags: ${JSON.stringify(metatags).replace(/"([^(")"]+)":/g, "$1:")}
                    categories: { set: [
                        ${categories.map((category) => `{
                            id: "${category.value}"
                        }`)}
                    ] }
                    tags: { set: ${tags} }
                    published: ${published}
                    publishedDate: "${publishedDate}"
                ) {
                    id
                    type
                    published
                    key
                    title
                    slug
                    excerpt
                    metatags
                    publishedDate
                    language {
                        id
                        name
                        slug
                    }
                    sections {
                        id
                        template {
                            id
                            title
                            key
                            structure
                        }
                        position
                        data
                    }
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updatePost) {
            toast.success("Saved successfully!");
            yield put(pageSaveSuccess(data.updatePost, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(pageSaveError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(pageSaveFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.PAGE_EDIT_REQUEST, pageEdit),
        takeLatest(actionTypes.PAGE_GET_REQUEST, pageGet),
        takeLatest(actionTypes.PAGE_SECTION_SAVE_REQUEST, pageSectionSave),
        takeLatest(actionTypes.PAGE_SAVE_REQUEST, pageSave)
    ])
}

export default sagas
