export const actionTypes = {
    SECTION_TEMPLATE_LIST_FAILURE: 'SECTION_TEMPLATE_LIST_FAILURE',
    SECTION_TEMPLATE_LIST_REQUEST: 'SECTION_TEMPLATE_LIST_REQUEST',
    SECTION_TEMPLATE_LIST_REQUEST_REQUESTING: 'SECTION_TEMPLATE_LIST_REQUEST_REQUESTING',
    SECTION_TEMPLATE_LIST_REQUEST_SUCCESS: 'SECTION_TEMPLATE_LIST_REQUEST_SUCCESS',
    SECTION_TEMPLATE_LIST_REQUEST_ERROR: 'SECTION_TEMPLATE_LIST_REQUEST_ERROR'
}

export function sectionTemplateListFailure(internalError) {
    return {
        type: actionTypes.SECTION_TEMPLATE_LIST_FAILURE,
        internalError
    }
}

export function sectionTemplateList() {
    return {
        type: actionTypes.SECTION_TEMPLATE_LIST_REQUEST,
        payload: {

        }
    }
}

export function sectionTemplateListRequesting() {
    return {
        type: actionTypes.SECTION_TEMPLATE_LIST_REQUEST_REQUESTING
    }
}

export function sectionTemplateListSuccess(data, errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_LIST_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function sectionTemplateListError(errors) {
    return {
        type: actionTypes.SECTION_TEMPLATE_LIST_REQUEST_ERROR,
        errors
    }
}