import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { actionTypes, contentTypeCreateError, contentTypeCreateFailure, contentTypeCreateRequesting, contentTypeCreateSuccess, contentTypeDeleteError, contentTypeDeleteFailure, contentTypeDeleteRequesting, contentTypeDeleteSuccess, contentTypeEditError, contentTypeEditFailure, contentTypeEditRequesting, contentTypeEditSuccess, contentTypeListError, contentTypeListFailure, contentTypeListRequesting, contentTypeListSuccess } from './actions';
import { toast } from 'react-toastify';

function* contentTypeList(action) {
    try {
        yield put(contentTypeListRequesting());

        const language = action.payload.language;

        const mutation = gql`
            query {
                contentTypes(where: {
                    language: {
                        id: "${language}"
                    }
                }) {
                    id
                    data
                    template {
                        title
                        structure
                    }
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.contentTypes) {
            yield put(contentTypeListSuccess(data.contentTypes, errors));
        } else {
            yield put(contentTypeListError(errors));
        }
    } catch (internalError) {
        yield put(contentTypeListFailure(internalError));
    }
}

function* contentTypeCreate(action) {
    try {
        yield put(contentTypeCreateRequesting());

        const template = action.payload.template;
        const language = action.payload.language;

        const mutation = `
            mutation {
                createContentType(
                    language: { connect: { id: "${language}" } }
                    template: { connect: { id: "${template}" } }
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createContentType) {
            toast.success("Saved successfully!");
            yield put(contentTypeCreateSuccess(data.createContentType, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(contentTypeCreateError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(contentTypeCreateFailure(internalError));
    }
}

function* contentTypeDelete(action) {
    try {
        yield put(contentTypeDeleteRequesting());

        const id = action.payload.id;

        const mutation = `
            mutation {
                deleteContentType(
                    id: "${id}"
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);

        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.deleteContentType) {
            toast.success("Deleted successfully!");
            yield put(contentTypeDeleteSuccess(data.deleteContentType, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(contentTypeDeleteError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(contentTypeDeleteFailure(internalError));
    }
}

function* contentTypeEdit(action) {
    try {
        yield put(contentTypeEditRequesting());

        const id = action.payload.id;
        const dataJson = action.payload.data;

        const mutation = `
            mutation {
                updateContentType(
                    id: "${id}"
                    data: ${JSON.stringify(dataJson).replace(/"([^(")"]+)":/g, "$1:")}
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateContentType) {
            toast.success("Saved successfully!");
            yield put(contentTypeEditSuccess(data.updateContentType, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(contentTypeEditError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(contentTypeEditFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.CONTENT_TYPE_LIST_REQUEST, contentTypeList),
        takeLatest(actionTypes.CONTENT_TYPE_CREATE_REQUEST, contentTypeCreate),
        takeLatest(actionTypes.CONTENT_TYPE_DELETE_REQUEST, contentTypeDelete),
        takeLatest(actionTypes.CONTENT_TYPE_EDIT_REQUEST, contentTypeEdit)
    ])
}

export default sagas
