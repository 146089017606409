import { actionTypes } from './actions';

export const initialState = {
    data: [],
    errors: {},
    internalError: undefined,
    type: undefined
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.PAGE_LIST_FAILURE:
        case actionTypes.PAGE_LIST_REQUEST_REQUESTING:
        case actionTypes.PAGE_LIST_REQUEST_SUCCESS:
        case actionTypes.PAGE_LIST_REQUEST_ERROR:
        case actionTypes.PAGE_CREATE_FAILURE:
        case actionTypes.PAGE_CREATE_REQUEST_REQUESTING:
        case actionTypes.PAGE_CREATE_REQUEST_SUCCESS:
        case actionTypes.PAGE_CREATE_REQUEST_ERROR:
        case actionTypes.PAGE_DELETE_FAILURE:
        case actionTypes.PAGE_DELETE_REQUEST_REQUESTING:
        case actionTypes.PAGE_DELETE_REQUEST_SUCCESS:
        case actionTypes.PAGE_DELETE_REQUEST_ERROR:
        case actionTypes.PAGE_RESET:
            return {
                ...state,
                ...action
            }
        default:
            return state
    }
}

export default reducer
