import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from './api';
import { actionTypes, globalGetError, globalGetFailure, globalGetRequesting, globalGetSuccess } from './globalActions';

function* globalGet(action) {
    try {
        yield put(globalGetRequesting());

        const mutation = `
            {
                settings {
                    id
                    key
                    value
                }
                languages {
                    id
                    name
                    slug
                }
                sectionTemplates {
                    id
                    title
                }
                contentTypeTemplates {
                    id
                    title
                    key
                    structure
                }
            }
               
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data) {
            yield put(globalGetSuccess(data, errors));
        } else {
            yield put(globalGetError(errors));
        }
    } catch (internalError) {
        yield put(globalGetFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.GLOBAL_GET_REQUEST, globalGet),
    ])
}

export default sagas
