export const actionTypes = {
    NEWS_RESET: 'NEWS_RESET',
    NEWS_LIST_FAILURE: 'NEWS_LIST_FAILURE',
    NEWS_LIST_REQUEST: 'NEWS_LIST_REQUEST',
    NEWS_LIST_REQUEST_REQUESTING: 'NEWS_LIST_REQUEST_REQUESTING',
    NEWS_LIST_REQUEST_SUCCESS: 'NEWS_LIST_REQUEST_SUCCESS',
    NEWS_LIST_REQUEST_ERROR: 'NEWS_LIST_REQUEST_ERROR',
    NEWS_CREATE_FAILURE: 'NEWS_CREATE_FAILURE',
    NEWS_CREATE_REQUEST: 'NEWS_CREATE_REQUEST',
    NEWS_CREATE_REQUEST_REQUESTING: 'NEWS_CREATE_REQUEST_REQUESTING',
    NEWS_CREATE_REQUEST_SUCCESS: 'NEWS_CREATE_REQUEST_SUCCESS',
    NEWS_CREATE_REQUEST_ERROR: 'NEWS_CREATE_REQUEST_ERROR',
    NEWS_DELETE_FAILURE: 'NEWS_DELETE_FAILURE',
    NEWS_DELETE_REQUEST: 'NEWS_DELETE_REQUEST',
    NEWS_DELETE_REQUEST_REQUESTING: 'NEWS_DELETE_REQUEST_REQUESTING',
    NEWS_DELETE_REQUEST_SUCCESS: 'NEWS_DELETE_REQUEST_SUCCESS',
    NEWS_DELETE_REQUEST_ERROR: 'NEWS_DELETE_REQUEST_ERROR'
}

export function newsReset() {
    return {
        type: actionTypes.NEWS_RESET
    }
}

export function newsListFailure(internalError) {
    return {
        type: actionTypes.NEWS_LIST_FAILURE,
        internalError
    }
}

export function newsList(language, first, after) {
    return {
        type: actionTypes.NEWS_LIST_REQUEST,
        payload: {
            language,
            first,
            after,
            join: !!after
        }
    }
}

export function newsListRequesting() {
    return {
        type: actionTypes.NEWS_LIST_REQUEST_REQUESTING
    }
}

export function newsListSuccess(data, join) {
    return {
        type: actionTypes.NEWS_LIST_REQUEST_SUCCESS,
        data,
        join
    }
}

export function newsListError(errors) {
    return {
        type: actionTypes.NEWS_LIST_REQUEST_ERROR,
        errors
    }
}

/// CREATE

export function newsCreateFailure(internalError) {
    return {
        type: actionTypes.NEWS_CREATE_FAILURE,
        internalError
    }
}

export function newsCreate(title, slug, language) {
    return {
        type: actionTypes.NEWS_CREATE_REQUEST,
        payload: {
            title,
            slug,
            language
        }
    }
}

export function newsCreateRequesting() {
    return {
        type: actionTypes.NEWS_CREATE_REQUEST_REQUESTING
    }
}

export function newsCreateSuccess(data, errors) {
    return {
        type: actionTypes.NEWS_CREATE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function newsCreateError(errors) {
    return {
        type: actionTypes.NEWS_CREATE_REQUEST_ERROR,
        errors
    }
}

/// DELETE

export function newsDeleteFailure(internalError) {
    return {
        type: actionTypes.NEWS_DELETE_FAILURE,
        internalError
    }
}

export function newsDelete(id) {
    return {
        type: actionTypes.NEWS_DELETE_REQUEST,
        payload: {
           id
        }
    }
}

export function newsDeleteRequesting() {
    return {
        type: actionTypes.NEWS_DELETE_REQUEST_REQUESTING
    }
}

export function newsDeleteSuccess(data, errors) {
    return {
        type: actionTypes.NEWS_DELETE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function newsDeleteError(errors) {
    return {
        type: actionTypes.NEWS_DELETE_REQUEST_ERROR,
        errors
    }
}