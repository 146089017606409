export const actionTypes = {
    CATEGORY_RESET: 'CATEGORY_RESET',
    CATEGORY_LIST_FAILURE: 'CATEGORY_LIST_FAILURE',
    CATEGORY_LIST_REQUEST: 'CATEGORY_LIST_REQUEST',
    CATEGORY_LIST_REQUEST_REQUESTING: 'CATEGORY_LIST_REQUEST_REQUESTING',
    CATEGORY_LIST_REQUEST_SUCCESS: 'CATEGORY_LIST_REQUEST_SUCCESS',
    CATEGORY_LIST_REQUEST_ERROR: 'CATEGORY_LIST_REQUEST_ERROR',
    CATEGORY_CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',
    CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
    CATEGORY_CREATE_REQUEST_REQUESTING: 'CATEGORY_CREATE_REQUEST_REQUESTING',
    CATEGORY_CREATE_REQUEST_SUCCESS: 'CATEGORY_CREATE_REQUEST_SUCCESS',
    CATEGORY_CREATE_REQUEST_ERROR: 'CATEGORY_CREATE_REQUEST_ERROR',
    CATEGORY_DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',
    CATEGORY_DELETE_REQUEST: 'CATEGORY_DELETE_REQUEST',
    CATEGORY_DELETE_REQUEST_REQUESTING: 'CATEGORY_DELETE_REQUEST_REQUESTING',
    CATEGORY_DELETE_REQUEST_SUCCESS: 'CATEGORY_DELETE_REQUEST_SUCCESS',
    CATEGORY_DELETE_REQUEST_ERROR: 'CATEGORY_DELETE_REQUEST_ERROR',
    CATEGORY_EDIT_FAILURE: 'CATEGORY_EDIT_FAILURE',
    CATEGORY_EDIT_REQUEST: 'CATEGORY_EDIT_REQUEST',
    CATEGORY_EDIT_REQUEST_REQUESTING: 'CATEGORY_EDIT_REQUEST_REQUESTING',
    CATEGORY_EDIT_REQUEST_SUCCESS: 'CATEGORY_EDIT_REQUEST_SUCCESS',
    CATEGORY_EDIT_REQUEST_ERROR: 'CATEGORY_EDIT_REQUEST_ERROR'
}

export function categoryReset() {
    return {
        type: actionTypes.CATEGORY_RESET
    }
}

export function categoryListFailure(internalError) {
    return {
        type: actionTypes.CATEGORY_LIST_FAILURE,
        internalError
    }
}

export function categoryList(language) {
    return {
        type: actionTypes.CATEGORY_LIST_REQUEST,
        payload: {
            language
        }
    }
}

export function categoryListRequesting() {
    return {
        type: actionTypes.CATEGORY_LIST_REQUEST_REQUESTING
    }
}

export function categoryListSuccess(data, errors) {
    return {
        type: actionTypes.CATEGORY_LIST_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function categoryListError(errors) {
    return {
        type: actionTypes.CATEGORY_LIST_REQUEST_ERROR,
        errors
    }
}

/// CREATE

export function categoryCreateFailure(internalError) {
    return {
        type: actionTypes.CATEGORY_CREATE_FAILURE,
        internalError
    }
}

export function categoryCreate(title, language) {
    return {
        type: actionTypes.CATEGORY_CREATE_REQUEST,
        payload: {
            title,
            language
        }
    }
}

export function categoryCreateRequesting() {
    return {
        type: actionTypes.CATEGORY_CREATE_REQUEST_REQUESTING
    }
}

export function categoryCreateSuccess(data, errors) {
    return {
        type: actionTypes.CATEGORY_CREATE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function categoryCreateError(errors) {
    return {
        type: actionTypes.CATEGORY_CREATE_REQUEST_ERROR,
        errors
    }
}

/// DELETE

export function categoryDeleteFailure(internalError) {
    return {
        type: actionTypes.CATEGORY_DELETE_FAILURE,
        internalError
    }
}

export function categoryDelete(id) {
    return {
        type: actionTypes.CATEGORY_DELETE_REQUEST,
        payload: {
           id
        }
    }
}

export function categoryDeleteRequesting() {
    return {
        type: actionTypes.CATEGORY_DELETE_REQUEST_REQUESTING
    }
}

export function categoryDeleteSuccess(data, errors) {
    return {
        type: actionTypes.CATEGORY_DELETE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function categoryDeleteError(errors) {
    return {
        type: actionTypes.CATEGORY_DELETE_REQUEST_ERROR,
        errors
    }
}

//////////////// EDIT

export function categoryEditFailure(internalError) {
    return {
        type: actionTypes.CATEGORY_EDIT_FAILURE,
        internalError
    }
}

export function categoryEdit(id, title) {
    return {
        type: actionTypes.CATEGORY_EDIT_REQUEST,
        payload: {
            id,
            title
        }
    }
}

export function categoryEditRequesting() {
    return {
        type: actionTypes.CATEGORY_EDIT_REQUEST_REQUESTING
    }
}

export function categoryEditSuccess(data, errors) {
    return {
        type: actionTypes.CATEGORY_EDIT_REQUEST_SUCCESS,
        errors
    }
}

export function categoryEditError(errors) {
    return {
        type: actionTypes.CATEGORY_EDIT_REQUEST_ERROR,
        errors
    }
}