import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { actionTypes, userCreateError, userCreateFailure, userCreateRequesting, userCreateSuccess, userDeleteError, userDeleteFailure, userDeleteRequesting, userDeleteSuccess, userEditError, userEditFailure, userEditRequesting, userEditSuccess, userListError, userListFailure, userListRequesting, userListSuccess } from './actions';
import { toast } from 'react-toastify';

function* userList(action) {
    try {
        yield put(userListRequesting());

        const mutation = gql`
            query {
                users {
                    id
                    email
                    name
                    role
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.users) {
            yield put(userListSuccess(data.users, errors));
        } else {
            yield put(userListError(errors));
        }
    } catch (internalError) {
        yield put(userListFailure(internalError));
    }
}

function* userCreate(action) {
    try {
        yield put(userCreateRequesting());

        const name = action.payload.name;
        const email = action.payload.email;
        const password = action.payload.password;
        const role = action.payload.role;

        const mutation = `
            mutation {
                createUser(
                    email: "${email}",
                    name: "${name}",
                    password: "${password}",
                    role: ${role})
                {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createUser) {
            toast.success("Saved successfully!");
            yield put(userCreateSuccess(data.createUser, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(userCreateError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(userCreateFailure(internalError));
    }
}

function* userDelete(action) {
    try {
        yield put(userDeleteRequesting());

        const id = action.payload.id;

        const mutation = `
            mutation {
                deleteUser(
                    id: "${id}"
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);

        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.deleteUser) {
            toast.success("Deleted successfully!");
            yield put(userDeleteSuccess(data.deleteUser, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(userDeleteError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(userDeleteFailure(internalError));
    }
}

function* userEdit(action) {
    try {
        yield put(userEditRequesting());

        const id = action.payload.id;
        const name = action.payload.name;
        const email = action.payload.email;
        const password = action.payload.password;
        const role = action.payload.role;

        const mutation = `
            mutation {
                updateUser(
                    id: "${id}",
                    email: "${email}",
                    name: "${name}",
                    ${password && password !== "" ? `password: "${password}"` : ""}
                    role: ${role}
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateUser) {
            toast.success("Saved successfully!");
            yield put(userEditSuccess(data.updateUser, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(userEditError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(userEditFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.USER_LIST_REQUEST, userList),
        takeLatest(actionTypes.USER_CREATE_REQUEST, userCreate),
        takeLatest(actionTypes.USER_DELETE_REQUEST, userDelete),
        takeLatest(actionTypes.USER_EDIT_REQUEST, userEdit)
    ])
}

export default sagas
