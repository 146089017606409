import { combineReducers } from 'redux';
import loginReducer from './views/Login/reducer';
import pageEditReducer from './views/Pages/Edit/reducer';
import pageListReducer from './views/Pages/List/reducer';
import sectionTemplateManageReducer from './views/SectionTemplates/Edit/reducer';
import sectionTemplateListReducer from './views/SectionTemplates/List/reducer';
import mediaReducer from './components/Media/reducer';
import globalReducer from './globalReducer';
import categoryListReducer from './views/Categories/List/reducer';
import contentTypeListReducer from './views/ContentTypes/List/reducer';
import userListReducer from './views/Users/List/reducer';
import headerReducer from './containers/DefaultLayout/reducer';
import newsListReducer from './views/News/List/reducer';
import newsEditReducer from './views/News/Edit/reducer';

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  pageListReducer: pageListReducer,
  pageEditReducer: pageEditReducer,
  sectionTemplateListReducer: sectionTemplateListReducer,
  sectionTemplateManageReducer: sectionTemplateManageReducer,
  mediaReducer: mediaReducer,
  globalReducer: globalReducer,
  categoryListReducer: categoryListReducer,
  contentTypeListReducer: contentTypeListReducer,
  userListReducer: userListReducer,
  headerReducer: headerReducer,
  newsListReducer: newsListReducer,
  newsEditReducer: newsEditReducer
})

export default rootReducer;