import { actionTypes } from './actions';

export const initialState = {
    data: [],
    errors: {},
    internalError: undefined,
    type: undefined
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.MEDIA_LIST_FAILURE:
        case actionTypes.MEDIA_LIST_REQUEST_REQUESTING:
        case actionTypes.MEDIA_LIST_REQUEST_SUCCESS:
        case actionTypes.MEDIA_LIST_REQUEST_ERROR:
        case actionTypes.MEDIA_CREATE_FAILURE:
        case actionTypes.MEDIA_CREATE_REQUEST_REQUESTING:
        case actionTypes.MEDIA_CREATE_REQUEST_SUCCESS:
        case actionTypes.MEDIA_CREATE_REQUEST_ERROR:
        case actionTypes.MEDIA_DELETE_FAILURE:
        case actionTypes.MEDIA_DELETE_REQUEST_REQUESTING:
        case actionTypes.MEDIA_DELETE_REQUEST_SUCCESS:
        case actionTypes.MEDIA_DELETE_REQUEST_ERROR:
        case actionTypes.MEDIA_RESET:
            if (action.join === true) {
                return {
                    data: [...state.data, ...action.data],
                    errors: action.errors,
                    internalError: action.internalError,
                    type: action.type
                }
            } else {
                return {
                    ...state,
                    ...action
                }
            }
        default:
            return state
    }
}

export default reducer
