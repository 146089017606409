import { actionTypes } from './actions';

export const initialState = {
    data: {
        post: {},
        categories: []
    },
    errors: {},
    internalError: undefined,
    type: undefined
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.NEWS_GET_FAILURE:
        case actionTypes.NEWS_GET_REQUEST_REQUESTING:
        case actionTypes.NEWS_GET_REQUEST_SUCCESS:
        case actionTypes.NEWS_GET_REQUEST_ERROR:
        case actionTypes.NEWS_EDIT_FAILURE:
        case actionTypes.NEWS_EDIT_REQUEST_REQUESTING:
        case actionTypes.NEWS_EDIT_REQUEST_SUCCESS:
        case actionTypes.NEWS_EDIT_REQUEST_ERROR:
        case actionTypes.NEWS_SECTION_SAVE_FAILURE:
        case actionTypes.NEWS_SECTION_SAVE_REQUEST_REQUESTING:
        case actionTypes.NEWS_SECTION_SAVE_REQUEST_SUCCESS:
        case actionTypes.NEWS_SECTION_SAVE_REQUEST_ERROR:
        case actionTypes.NEWS_SAVE_FAILURE:
        case actionTypes.NEWS_SAVE_REQUEST_REQUESTING:
        case actionTypes.NEWS_SAVE_REQUEST_SUCCESS:
        case actionTypes.NEWS_SAVE_REQUEST_ERROR:
            return {
                ...state,
                ...action
            }
        case actionTypes.NEWS_EDIT_RESET:
            return {
                data: {
                    post: {},
                    categories: []
                },
                errors: {},
                internalError: undefined,
                type: undefined
            }
        default:
            return state
    }
}

export default reducer
