import es6promise from 'es6-promise';
import 'isomorphic-unfetch';
import { all, fork } from 'redux-saga/effects';
import login from './views/Login/saga';
import pageEdit from './views/Pages/Edit/saga';
import pageList from './views/Pages/List/saga';
import sectionTemplateManage from './views/SectionTemplates/Edit/saga';
import sectionTemplateList from './views/SectionTemplates/List/saga';
import media from './components/Media/saga';
import global from './globalSaga';
import categoryList from './views/Categories/List/saga';
import contentTypeList from './views/ContentTypes/List/saga';
import userList from './views/Users/List/saga';
import header from './containers/DefaultLayout/saga';
import newsList from './views/News/List/saga';
import newsEdit from './views/News/Edit/saga';

es6promise.polyfill()

function* rootSaga() {
  yield all([
    fork(login),
    fork(pageList),
    fork(pageEdit),
    fork(sectionTemplateList),
    fork(sectionTemplateManage),
    fork(media),
    fork(global),
    fork(categoryList),
    fork(contentTypeList),
    fork(userList),
    fork(header),
    fork(newsList),
    fork(newsEdit)
  ])
}

export default rootSaga
