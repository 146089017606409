export const actionTypes = {
    GLOBAL_GET_FAILURE: 'GLOBAL_GET_FAILURE',
    GLOBAL_GET_REQUEST: 'GLOBAL_GET_REQUEST',
    GLOBAL_GET_REQUEST_REQUESTING: 'GLOBAL_GET_REQUEST_REQUESTING',
    GLOBAL_GET_REQUEST_SUCCESS: 'GLOBAL_GET_REQUEST_SUCCESS',
    GLOBAL_GET_REQUEST_ERROR: 'GLOBAL_GET_REQUEST_ERROR',
}

export function globalGetFailure(internalError) {
    return {
        type: actionTypes.GLOBAL_GET_FAILURE,
        internalError
    }
}

export function globalGet(id) {
    return {
        type: actionTypes.GLOBAL_GET_REQUEST,
        payload: {
            id
        }
    }
}

export function globalGetRequesting() {
    return {
        type: actionTypes.GLOBAL_GET_REQUEST_REQUESTING
    }
}

export function globalGetSuccess(data, errors) {
    return {
        type: actionTypes.GLOBAL_GET_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function globalGetError(errors) {
    return {
        type: actionTypes.GLOBAL_GET_REQUEST_ERROR,
        errors
    }
}