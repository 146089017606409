export const actionTypes = {
    ME_RESET: 'ME_RESET',
    ME_UPDATE_FAILURE: 'ME_UPDATE_FAILURE',
    ME_UPDATE_REQUEST: 'ME_UPDATE_REQUEST',
    ME_UPDATE_REQUEST_REQUESTING: 'ME_UPDATE_REQUEST_REQUESTING',
    ME_UPDATE_REQUEST_SUCCESS: 'ME_UPDATE_REQUEST_SUCCESS',
    ME_UPDATE_REQUEST_ERROR: 'ME_UPDATE_REQUEST_ERROR',
}

export function meUpdateReset() {
    return {
        type: actionTypes.ME_RESET
    }
}

export function meUpdateFailure(internalError) {
    return {
        type: actionTypes.ME_UPDATE_FAILURE,
        internalError
    }
}

export function meUpdate(name, email, password) {
    return {
        type: actionTypes.ME_UPDATE_REQUEST,
        payload: {
            name, email, password
        }
    }
}

export function meUpdateRequesting() {
    return {
        type: actionTypes.ME_UPDATE_REQUEST_REQUESTING
    }
}

export function meUpdateSuccess(data, errors) {
    return {
        type: actionTypes.ME_UPDATE_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function meUpdateError(errors) {
    return {
        type: actionTypes.ME_UPDATE_REQUEST_ERROR,
        errors
    }
}