import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import axios from "axios";
import { setContext } from "apollo-link-context";
import { from } from "apollo-link";
import { config } from "./config";

let currentUserId = null;
let currentUserToken = null;

const api = axios.create({
  baseURL: config.BACKEND_HOST,
  timeout: 10000,
  headers: {
    "Content-Type": "application/graphql",
  },
  // crossdomain: true,
  validateStatus: (status) => {
    return true;
  },
});

export default api;

export const getCurrentUserId = () => currentUserId;

export const setCurrentUserId = (id) => {
  currentUserId = id;
};

export const getCurrentUserToken = () => currentUserToken;

export const setCurrentUserToken = (token) => {
  currentUserToken = token;
};

const authMiddleware = setContext((operation, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: "Bearer " + getCurrentUserToken(),
    },
  };
});

const link = createUploadLink({ uri: config.BACKEND_HOST });

export const client = new ApolloClient({
  link: from([authMiddleware, link]),
  cache: new InMemoryCache(),
});
