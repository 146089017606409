export const actionTypes = {
    CONTENT_TYPE_RESET: 'CONTENT_TYPE_RESET',
    CONTENT_TYPE_LIST_FAILURE: 'CONTENT_TYPE_LIST_FAILURE',
    CONTENT_TYPE_LIST_REQUEST: 'CONTENT_TYPE_LIST_REQUEST',
    CONTENT_TYPE_LIST_REQUEST_REQUESTING: 'CONTENT_TYPE_LIST_REQUEST_REQUESTING',
    CONTENT_TYPE_LIST_REQUEST_SUCCESS: 'CONTENT_TYPE_LIST_REQUEST_SUCCESS',
    CONTENT_TYPE_LIST_REQUEST_ERROR: 'CONTENT_TYPE_LIST_REQUEST_ERROR',
    CONTENT_TYPE_CREATE_FAILURE: 'CONTENT_TYPE_CREATE_FAILURE',
    CONTENT_TYPE_CREATE_REQUEST: 'CONTENT_TYPE_CREATE_REQUEST',
    CONTENT_TYPE_CREATE_REQUEST_REQUESTING: 'CONTENT_TYPE_CREATE_REQUEST_REQUESTING',
    CONTENT_TYPE_CREATE_REQUEST_SUCCESS: 'CONTENT_TYPE_CREATE_REQUEST_SUCCESS',
    CONTENT_TYPE_CREATE_REQUEST_ERROR: 'CONTENT_TYPE_CREATE_REQUEST_ERROR',
    CONTENT_TYPE_DELETE_FAILURE: 'CONTENT_TYPE_DELETE_FAILURE',
    CONTENT_TYPE_DELETE_REQUEST: 'CONTENT_TYPE_DELETE_REQUEST',
    CONTENT_TYPE_DELETE_REQUEST_REQUESTING: 'CONTENT_TYPE_DELETE_REQUEST_REQUESTING',
    CONTENT_TYPE_DELETE_REQUEST_SUCCESS: 'CONTENT_TYPE_DELETE_REQUEST_SUCCESS',
    CONTENT_TYPE_DELETE_REQUEST_ERROR: 'CONTENT_TYPE_DELETE_REQUEST_ERROR',
    CONTENT_TYPE_EDIT_FAILURE: 'CONTENT_TYPE_EDIT_FAILURE',
    CONTENT_TYPE_EDIT_REQUEST: 'CONTENT_TYPE_EDIT_REQUEST',
    CONTENT_TYPE_EDIT_REQUEST_REQUESTING: 'CONTENT_TYPE_EDIT_REQUEST_REQUESTING',
    CONTENT_TYPE_EDIT_REQUEST_SUCCESS: 'CONTENT_TYPE_EDIT_REQUEST_SUCCESS',
    CONTENT_TYPE_EDIT_REQUEST_ERROR: 'CONTENT_TYPE_EDIT_REQUEST_ERROR'
}

export function contentTypeReset() {
    return {
        type: actionTypes.CONTENT_TYPE_RESET
    }
}

export function contentTypeListFailure(internalError) {
    return {
        type: actionTypes.CONTENT_TYPE_LIST_FAILURE,
        internalError
    }
}

export function contentTypeList(language) {
    return {
        type: actionTypes.CONTENT_TYPE_LIST_REQUEST,
        payload: {
            language
        }
    }
}

export function contentTypeListRequesting() {
    return {
        type: actionTypes.CONTENT_TYPE_LIST_REQUEST_REQUESTING
    }
}

export function contentTypeListSuccess(data, errors) {
    return {
        type: actionTypes.CONTENT_TYPE_LIST_REQUEST_SUCCESS,
        data,
        errors
    }
}

export function contentTypeListError(errors) {
    return {
        type: actionTypes.CONTENT_TYPE_LIST_REQUEST_ERROR,
        errors
    }
}

/// CREATE

export function contentTypeCreateFailure(internalError) {
    return {
        type: actionTypes.CONTENT_TYPE_CREATE_FAILURE,
        internalError
    }
}

export function contentTypeCreate(language, template) {
    return {
        type: actionTypes.CONTENT_TYPE_CREATE_REQUEST,
        payload: {
            language,
            template
        }
    }
}

export function contentTypeCreateRequesting() {
    return {
        type: actionTypes.CONTENT_TYPE_CREATE_REQUEST_REQUESTING
    }
}

export function contentTypeCreateSuccess(data, errors) {
    return {
        type: actionTypes.CONTENT_TYPE_CREATE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function contentTypeCreateError(errors) {
    return {
        type: actionTypes.CONTENT_TYPE_CREATE_REQUEST_ERROR,
        errors
    }
}

/// DELETE

export function contentTypeDeleteFailure(internalError) {
    return {
        type: actionTypes.CONTENT_TYPE_DELETE_FAILURE,
        internalError
    }
}

export function contentTypeDelete(id) {
    return {
        type: actionTypes.CONTENT_TYPE_DELETE_REQUEST,
        payload: {
           id
        }
    }
}

export function contentTypeDeleteRequesting() {
    return {
        type: actionTypes.CONTENT_TYPE_DELETE_REQUEST_REQUESTING
    }
}

export function contentTypeDeleteSuccess(data, errors) {
    return {
        type: actionTypes.CONTENT_TYPE_DELETE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function contentTypeDeleteError(errors) {
    return {
        type: actionTypes.CONTENT_TYPE_DELETE_REQUEST_ERROR,
        errors
    }
}

//////////////// EDIT

export function contentTypeEditFailure(internalError) {
    return {
        type: actionTypes.CONTENT_TYPE_EDIT_FAILURE,
        internalError
    }
}

export function contentTypeEdit(id, data) {
    return {
        type: actionTypes.CONTENT_TYPE_EDIT_REQUEST,
        payload: {
            id,
            data
        }
    }
}

export function contentTypeEditRequesting() {
    return {
        type: actionTypes.CONTENT_TYPE_EDIT_REQUEST_REQUESTING
    }
}

export function contentTypeEditSuccess(data, errors) {
    return {
        type: actionTypes.CONTENT_TYPE_EDIT_REQUEST_SUCCESS,
        errors
    }
}

export function contentTypeEditError(errors) {
    return {
        type: actionTypes.CONTENT_TYPE_EDIT_REQUEST_ERROR,
        errors
    }
}