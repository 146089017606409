import api from '../../../api';
import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import { actionTypes, sectionTemplateListFailure, sectionTemplateListError, sectionTemplateListRequesting, sectionTemplateListSuccess } from './actions';

function* sectionTemplateList(action) {
    try {
        yield put(sectionTemplateListRequesting());

        const mutation = gql`
            query {
                sectionTemplates{
                    id
                    title
                    key
                    structure
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.sectionTemplates) {
            yield put(sectionTemplateListSuccess(data.sectionTemplates, errors));
        } else {
            yield put(sectionTemplateListError(errors));
        }
    } catch (internalError) {
        yield put(sectionTemplateListFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.SECTION_TEMPLATE_LIST_REQUEST, sectionTemplateList)
    ])
}

export default sagas
