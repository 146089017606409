import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { actionTypes, newsEditError, newsEditFailure, newsEditRequesting, newsEditSuccess, newsGetError, newsGetFailure, newsGetRequesting, newsGetSuccess, newsSectionSaveRequesting, newsSectionSaveSuccess, newsSectionSaveError, newsSectionSaveFailure, newsSaveRequesting, newsSaveSuccess, newsSaveError, newsSaveFailure } from './actions';
import { toast } from 'react-toastify';

function* newsGet(action) {
    try {
        yield put(newsGetRequesting());

        const id = action.payload.id;

        const mutation = `
            query {
                categories {
                    id
                    title
                    language {
                        id
                    }
                }
                post(id: "${id}") {
                    id
                    key
                    type
                    published
                    title
                    excerpt
                    thumbnail
                    featured
                    slug
                    metatags
                    publishedDate
                    tags
                    categories {
                        id
                        title
                    }
                    language {
                        id
                        name
                        slug
                    }
                    sections {
                        id
                        template {
                            id
                            title
                            key
                            structure
                        }
                        position
                        data
                    }
                }
            }          
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data) {
            yield put(newsGetSuccess(data, errors));
        } else {
            yield put(newsGetError(errors));
        }
    } catch (internalError) {
        yield put(newsGetFailure(internalError));
    }
}

function* newsEdit(action) {
    try {
        yield put(newsEditRequesting());

        const id = action.payload.id;
        const title = action.payload.title;
        const key = action.payload.key;
        const structure = action.payload.structure;

        const mutation = `
            mutation {
                updateSectionTemplate(
                    id: "${id}"
                    title: "${title}"
                    key: "${key}"
                    structure: ${structure}
                ) {
                    id
                    title
                    key
                    structure
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateSectionTemplate) {
            yield put(newsEditSuccess(data.updateSectionTemplate, errors));
        } else {
            yield put(newsEditError(errors));
        }
    } catch (internalError) {
        yield put(newsEditFailure(internalError));
    }
}

function* newsSectionSave(action) {
    try {
        yield put(newsSectionSaveRequesting());

        const id = action.payload.id;
        const sectionData = action.payload.data;

        const mutation = `
            mutation {
                updateSection(
                    id: "${id}"
                    data: ${JSON.stringify(sectionData).replace(/"([^(")"]+)":/g, "$1:")}
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateSection) {
            toast.success("Saved successfully!");
            yield put(newsSectionSaveSuccess(data.updateSection, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(newsSectionSaveError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(newsSectionSaveFailure(internalError));
    }
}

function* newsSave(action) {
    try {
        yield put(newsSaveRequesting());

        const id = action.payload.id;
        const key = action.payload.key;
        const title = action.payload.title;
        const slug = action.payload.slug;
        const excerpt = action.payload.excerpt;
        const featured = action.payload.featured;
        const thumbnail = action.payload.thumbnail;
        const published = action.payload.published;
        const metatags = action.payload.metatags;
        const publishedDate = action.payload.publishedDate;
        const tags = "[" + action.payload.tags.split(",").map((tag) => "\"" + tag + "\"") + "]";
        const categories = action.payload.categories;

        const mutation = `
            mutation {
                updatePost(
                    id: "${id}"
                    key: "${key}"
                    title: "${title}"
                    excerpt: "${excerpt}"
                    thumbnail: ${JSON.stringify(thumbnail).replace(/"([^(")"]+)":/g, "$1:")}
                    slug: "${slug}"
                    featured: ${featured}
                    metatags: ${JSON.stringify(metatags).replace(/"([^(")"]+)":/g, "$1:")}
                    categories: { set: [
                        ${categories.map((category) => `{
                            id: "${category.value}"
                        }`)}
                    ] }
                    tags: { set: ${tags} }
                    published: ${published}
                    publishedDate: "${publishedDate}"
                ) {
                    id
                    type
                    published
                    key
                    title
                    slug
                    excerpt
                    thumbnail
                    featured
                    metatags
                    publishedDate
                    language {
                        id
                        name
                        slug
                    }
                    sections {
                        id
                        template {
                            id
                            title
                            key
                            structure
                        }
                        position
                        data
                    }
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updatePost) {
            toast.success("Saved successfully!");
            yield put(newsSaveSuccess(data.updatePost, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(newsSaveError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(newsSaveFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.NEWS_EDIT_REQUEST, newsEdit),
        takeLatest(actionTypes.NEWS_GET_REQUEST, newsGet),
        takeLatest(actionTypes.NEWS_SECTION_SAVE_REQUEST, newsSectionSave),
        takeLatest(actionTypes.NEWS_SAVE_REQUEST, newsSave)
    ])
}

export default sagas
