import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api, { setCurrentUserId, setCurrentUserToken } from '../../api';
import { actionTypes, loginError, loginFailure, loginRequesting, loginSuccess } from './actions';

function* login(action) {
    try {
        yield put(loginRequesting());
        const email = action.payload.email;
        const password = action.payload.password;

        const mutation = gql`
            mutation {
                login(email:"${email}", password:"${password}") {
                    token
                    user {
                        id
                        email
                        name
                        role
                    }
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.login) {
            if (data.login.token) {
                setCurrentUserId(data.login.user.id);
                setCurrentUserToken(data.login.token);
                api.defaults.headers.common['Authorization'] = 'Bearer ' + data.login.token;
            }
            yield put(loginSuccess(data.login, errors));
        } else {
            yield put(loginError(errors));
        }
    } catch (internalError) {
        yield put(loginFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.LOGIN_REQUEST, login)
    ])
}

export default sagas
