export const actionTypes = {
    MEDIA_RESET: 'MEDIA_RESET',
    MEDIA_LIST_FAILURE: 'MEDIA_LIST_FAILURE',
    MEDIA_LIST_REQUEST: 'MEDIA_LIST_REQUEST',
    MEDIA_LIST_REQUEST_REQUESTING: 'MEDIA_LIST_REQUEST_REQUESTING',
    MEDIA_LIST_REQUEST_SUCCESS: 'MEDIA_LIST_REQUEST_SUCCESS',
    MEDIA_LIST_REQUEST_ERROR: 'MEDIA_LIST_REQUEST_ERROR',
    MEDIA_CREATE_FAILURE: 'MEDIA_CREATE_FAILURE',
    MEDIA_CREATE_REQUEST: 'MEDIA_CREATE_REQUEST',
    MEDIA_CREATE_REQUEST_REQUESTING: 'MEDIA_CREATE_REQUEST_REQUESTING',
    MEDIA_CREATE_REQUEST_SUCCESS: 'MEDIA_CREATE_REQUEST_SUCCESS',
    MEDIA_CREATE_REQUEST_ERROR: 'MEDIA_CREATE_REQUEST_ERROR',
    MEDIA_DELETE_FAILURE: 'MEDIA_DELETE_FAILURE',
    MEDIA_DELETE_REQUEST: 'MEDIA_DELETE_REQUEST',
    MEDIA_DELETE_REQUEST_REQUESTING: 'MEDIA_DELETE_REQUEST_REQUESTING',
    MEDIA_DELETE_REQUEST_SUCCESS: 'MEDIA_DELETE_REQUEST_SUCCESS',
    MEDIA_DELETE_REQUEST_ERROR: 'MEDIA_DELETE_REQUEST_ERROR'
}

export function mediaReset() {
    return {
        type: actionTypes.MEDIA_RESET
    }
}

export function mediaListFailure(internalError) {
    return {
        type: actionTypes.MEDIA_LIST_FAILURE,
        internalError
    }
}

export function mediaList(first, after) {
    return {
        type: actionTypes.MEDIA_LIST_REQUEST,
        payload: {
            first,
            after
        }
    }
}

export function mediaListRequesting() {
    return {
        type: actionTypes.MEDIA_LIST_REQUEST_REQUESTING
    }
}

export function mediaListSuccess(data, errors, join) {
    return {
        type: actionTypes.MEDIA_LIST_REQUEST_SUCCESS,
        data,
        errors,
        join
    }
}

export function mediaListError(errors) {
    return {
        type: actionTypes.MEDIA_LIST_REQUEST_ERROR,
        errors
    }
}

/// CREATE

export function mediaCreateFailure(internalError) {
    return {
        type: actionTypes.MEDIA_CREATE_FAILURE,
        internalError
    }
}

export function mediaCreate(title, file) {
    return {
        type: actionTypes.MEDIA_CREATE_REQUEST,
        payload: {
            title,
            file
        }
    }
}

export function mediaCreateRequesting() {
    return {
        type: actionTypes.MEDIA_CREATE_REQUEST_REQUESTING
    }
}

export function mediaCreateSuccess(data, errors) {
    return {
        type: actionTypes.MEDIA_CREATE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function mediaCreateError(errors) {
    return {
        type: actionTypes.MEDIA_CREATE_REQUEST_ERROR,
        errors
    }
}

/// DELETE

export function mediaDeleteFailure(internalError) {
    return {
        type: actionTypes.MEDIA_DELETE_FAILURE,
        internalError
    }
}

export function mediaDelete(id) {
    return {
        type: actionTypes.MEDIA_DELETE_REQUEST,
        payload: {
            id
        }
    }
}

export function mediaDeleteRequesting() {
    return {
        type: actionTypes.MEDIA_DELETE_REQUEST_REQUESTING
    }
}

export function mediaDeleteSuccess(data, errors) {
    return {
        type: actionTypes.MEDIA_DELETE_REQUEST_SUCCESS,
        createData: data,
        errors
    }
}

export function mediaDeleteError(errors) {
    return {
        type: actionTypes.MEDIA_DELETE_REQUEST_ERROR,
        errors
    }
}