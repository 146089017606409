import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { actionTypes, mediaCreateError, mediaCreateFailure, mediaCreateRequesting, mediaCreateSuccess, mediaListError, mediaListFailure, mediaListRequesting, mediaListSuccess, mediaDeleteRequesting, mediaDeleteSuccess, mediaDeleteError, mediaDeleteFailure, mediaList } from './actions';

function* mediaGet(action) {
    try {
        const first = action.payload.first;
        const after = action.payload.after;

        yield put(mediaListRequesting());

        const mutation = gql`
            {
                files(first: ${first}, ${after ? `after: "${after}"` : ``}) {
                    id
                    title
                    name
                    type
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.files) {
            yield put(mediaListSuccess(data.files, errors, after ? true : false));
        } else {
            yield put(mediaListError(errors));
        }
    } catch (internalError) {
        yield put(mediaListFailure(internalError));
    }
}

function* mediaCreate(action) {
    try {
        yield put(mediaCreateRequesting());

        const title = action.payload.title;
        const file = action.payload.file;


        const mutation = `
            mutation {
                createFile(title: "${title}", file: ${file}) {
                    id
                    title
                    name
                    type
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.createFile) {
            yield put(mediaCreateSuccess(data.createFile, errors));
        } else {
            yield put(mediaCreateError(errors));
        }
    } catch (internalError) {
        yield put(mediaCreateFailure(internalError));
    }
}

function* mediaDelete(action) {
    try {
        yield put(mediaDeleteRequesting());

        const id = action.payload.id;

        const mutation = `
            mutation {
                deleteFile(id: "${id}") {
                    id
                    title
                    name
                    type
                }
            }
        `;

        const response = yield api.post('/', mutation);
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.deleteFile) {
            yield put(mediaDeleteSuccess(data.deleteFile, errors));
            yield put(mediaList(12, null));
        } else {
            yield put(mediaDeleteError(errors));
        }
    } catch (internalError) {
        yield put(mediaDeleteFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.MEDIA_LIST_REQUEST, mediaGet),
        takeLatest(actionTypes.MEDIA_CREATE_REQUEST, mediaCreate),
        takeLatest(actionTypes.MEDIA_DELETE_REQUEST, mediaDelete)
    ])
}

export default sagas
