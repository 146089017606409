import { print } from 'graphql';
import gql from 'graphql-tag';
import 'isomorphic-unfetch';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { actionTypes, meUpdateError, meUpdateFailure, meUpdateRequesting, meUpdateSuccess } from './actions';
import { toast } from 'react-toastify';

function* meUpdate(action) {
    try {
        yield put(meUpdateRequesting());

        const name = action.payload.name;
        const email = action.payload.email;
        const password = action.payload.password;

        const mutation = gql`
            mutation {
                updateMe(
                    name: "${name}",
                    email: "${email}"
                    ${password && password !== "" ? `, password: "${password}"` : ""}
                ) {
                    id
                }
            }
        `;

        const response = yield api.post('/', print(mutation));
        const data = response.data.data
        const errors = response.data.errors;

        if (data && data.updateMe) {
            toast.success("Saved successfully!");
            yield put(meUpdateSuccess(data.updateMe, errors));
        } else {
            toast.error("Error, please try again!");
            yield put(meUpdateError(errors));
        }
    } catch (internalError) {
        toast.error("Internal Error! Contact your admin.");
        yield put(meUpdateFailure(internalError));
    }
}

function* sagas() {
    yield all([
        takeLatest(actionTypes.ME_UPDATE_REQUEST, meUpdate)
    ])
}

export default sagas
